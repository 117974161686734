var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('ValueCards'),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12","md":"7","xl":"8"}},[_c('div',{staticClass:"px-md-6 py-4",staticStyle:{"opacity":"0.9"}},[_c('SponsorsHeader')],1),_c('HistoricalProfileCard')],1),_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","md":"5","xl":"4"}},[_c('KPICards')],1),_c('v-col',{staticClass:"pt-6 d-flex",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-3 flex-grow-1",style:({
            filter: _vm.darkmode
              ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
              : '',
          }),attrs:{"dark":_vm.darkmode}},[_c('div',{staticClass:"card-header-padding"},[_c('p',{staticClass:"font-weight-600 text-h3 mb-0 d-flex justify-space-between align-center fill-height",class:{ 'text-muted': !_vm.darkmode }},[_c('span',{staticClass:"text-muted text-uppercase text-h5 font-weight-600 d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 text-muted"},[_vm._v(" mdi-wallet-outline ")]),_vm._v(" Wallet ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false},on:{"click":function($event){_vm.showWallet = !_vm.showWallet}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"show-button",attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showWallet ? "mdi-minus-thick" : "mdi-plus-thick")+" ")])],1)]}}])},[(_vm.showWallet)?_c('span',[_vm._v("Hide Wallet Details")]):_c('span',[_vm._v("Show Wallet Details")])])],1)]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWallet),expression:"showWallet"}]},[_c('WalletsWithoutData'),_c('v-divider'),_c('Wallet')],1)])],1)],1)],1),_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"mb-6",style:({
            filter: _vm.darkmode
              ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
              : '',
          }),attrs:{"dark":_vm.darkmode}},[_c('div',{staticClass:"card-header-padding"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('p',{staticClass:"font-weight-600 text-h3 mb-0 d-flex justify-space-between align-center fill-height",class:{ 'text-muted': !_vm.darkmode }},[_c('span',{staticClass:"text-muted text-uppercase text-h5 font-weight-600 d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 text-muted"},[_vm._v(" mdi-tractor ")]),_vm._v(" Farms ")],1)])]),_c('v-col',{staticClass:"text-right d-flex align-center justify-start justify-sm-end",attrs:{"cols":"12","sm":"auto"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-2 mr-2"},[_vm._v(" Export: ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","outlined":"","x-small":"","disabled":_vm.loading || Object.keys(_vm.farmsWithData).length === 0},on:{"click":function($event){return _vm.csvExport()}}},[_vm._v(" CSV ")]),_c('v-btn',{staticClass:"mr-6",attrs:{"text":"","outlined":"","x-small":"","disabled":_vm.loading || Object.keys(_vm.farmsWithData).length === 0},on:{"click":function($event){return _vm.jsonExport()}}},[_vm._v(" JSON ")]),_c('span',{staticClass:"font-weight-bold text-subtitle-2 mr-2"},[_vm._v(" View: ")]),_c('p',{staticClass:"font-weight-600 text-h3 mb-0 mr-2",class:{ 'text-muted': !_vm.darkmode }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 pb-1 toggle",on:{"click":function($event){return _vm.toggleCompactView()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.compactView ? "fas fa-list" : "fas fa-th-large")+" ")])]}}])},[(_vm.compactView)?_c('span',[_vm._v("Toggle Card View")]):_c('span',[_vm._v("Toggle List View")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false},on:{"click":function($event){_vm.showFarms = !_vm.showFarms}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"show-button",attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showFarms ? "mdi-minus-thick" : "mdi-plus-thick")+" ")])],1)]}}])},[(_vm.showFarms)?_c('span',[_vm._v("Hide Farms Details")]):_c('span',[_vm._v("Show Farms Details")])])],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFarms),expression:"showFarms"}]},[_c('FarmsWithoutData'),_c('v-divider'),_c('Farms')],1)])],1)],1)],1),(_vm.compactView)?_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"mb-6",style:({
            filter: _vm.darkmode
              ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
              : '',
          }),attrs:{"dark":_vm.darkmode}},[_c('div',{staticClass:"card-header-padding"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted text-uppercase text-h5 font-weight-600 d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 text-muted"},[_vm._v(" mdi-hand-coin ")]),_vm._v(" Lending / Borrowing ")],1)]),_c('v-col',{staticClass:"text-right d-flex align-center justify-start justify-sm-end",attrs:{"cols":"12","sm":"6"}},[(
                    _vm.lendingFarms.length > 0 && !_vm.$store.state.farmStore.loading
                  )?_c('span',{staticClass:"font-weight-bold text-subtitle-2 mr-2"},[_vm._v(" Total: "+_vm._s(_vm._f("toSelectedCurrency")(_vm.total))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false},on:{"click":function($event){_vm.showLending = !_vm.showLending}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"show-button",attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showLending ? "mdi-minus-thick" : "mdi-plus-thick")+" ")])],1)]}}],null,false,3599137554)},[(_vm.showLending)?_c('span',[_vm._v("Hide Farms Details")]):_c('span',[_vm._v("Show Farms Details")])])],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-slide-y-transition',[(_vm.showLending)?_c('div',[_c('v-divider'),_c('LendingFarmsTable')],1):_vm._e()])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
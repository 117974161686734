var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6",style:({
    filter: _vm.darkmode
      ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
      : '',
  }),attrs:{"dark":_vm.darkmode}},[_c('v-card-text',{staticClass:"card-stats-padding"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" fas fa-cubes ")]),_c('h4',{staticClass:"font-weight-600 text-muted text-uppercase text-h5 mb-0"},[_vm._v(" Network Distribution ")])],1),_c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"width":"500","overlay-color":_vm.darkmode ? 'grey' : 'rgb(33, 33, 33)',"overlay-opacity":_vm.darkmode ? 0.2 : 0.46},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-caption text-none font-weight-bold white--text",attrs:{"elevation":"0","small":"","dark":_vm.darkmode,"color":"indigo lighten-1","disabled":_vm.farmsByNetwork.length === 0}},'v-btn',attrs,false),on),[_vm._v(" View All ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"black-shadow",staticStyle:{"max-height":"600px"},attrs:{"dark":_vm.darkmode}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold flex justify-center lighten-2"},[_vm._v(" Network Distribution ")]),_c('v-divider'),_c('v-card-text',{staticClass:"custom-scrollbar pa-0",staticStyle:{"max-height":"500px","overflow-y":"auto","overflow-x":"hidden"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.farmsByNetwork,"item-key":"name","hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"font-weight-bold flex"},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getNetworkLogo(item.network)}})],1),_vm._v(" "+_vm._s(_vm.$t(item.network))+" ")],1),_c('td',[_vm._v(_vm._s(_vm._f("toSelectedCurrency")(item.total)))]),_c('td',[_c('span',{staticClass:"text-right",staticStyle:{"min-width":"50px"}},[_vm._v(" "+_vm._s(_vm.getPercentage(item.total).toFixed(2))+"% ")])])])]}}])})],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","indeterminate":_vm.loading,"color":"indigo lighten-1"},slot:"progress"}),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"})]),(_vm.farmsByNetwork.length > 0)?_c('div',_vm._l((_vm.farmsByNetwork.length > 5
          ? _vm.farmsByNetwork.slice(0, 5)
          : _vm.farmsByNetwork),function(farm,index){return _c('div',{key:index,staticClass:"d-flex align-center mb-1"},[_c('div',{staticClass:"d-flex justify-center align-center mr-1",staticStyle:{"width":"30px"}},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getNetworkLogo(farm.network)}})],1)],1),_c('span',{staticClass:"font-weight-bold",staticStyle:{"min-width":"140px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t(farm.network))+" ")]),_c('v-tooltip',{attrs:{"top":"","color":_vm.darkmode ? 'white' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"value":_vm.getPercentage(farm.total),"rounded":"","height":"7","color":"indigo lighten-1"}},'v-progress-linear',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-caption font-weight-bold",class:_vm.darkmode ? 'blue--text' : ''},[_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(farm.total))+" ")])]),_c('span',{staticClass:"text-right",staticStyle:{"min-width":"50px"}},[_vm._v(" "+_vm._s(_vm.getPercentage(farm.total).toFixed(2))+"% ")])],1)}),0):_c('div',{staticClass:"d-flex align-center justify-content-center flex-column py-10 w-full"},[_vm._v(" No data available... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":_vm.loading,"color":"indigo lighten-1"}}),_c('v-card',{staticClass:"rounded-t-0"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"})]),_c('v-data-table',{staticClass:"table px-4",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.poolsWithoutTotalLP,"loading":_vm.loading,"hide-default-footer":"","items-per-page":-1,"sort-by":"poolValue","sort-desc":true},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-sm-column"},[(
                item.contractAddress && item.rawPending > 0 && item.poolID
              )?_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","disabled":!_vm.connectedWallet ||
                      item.rawPending < 1 ||
                      item.network != _vm.connectedWalletNetwork ||
                      item.wallet !== _vm.connectedWallet,"block":"","elevation":"2","outlined":"","x-small":""},on:{"click":function($event){return _vm.claimReward({
                        contractAddress: item.contractAddress,
                        poolIndex: item.poolID,
                        rawTokens: item.rawPending,
                      })}}},'v-btn',attrs,false),on),[_vm._v(" HARVEST ")])]}}],null,true)},[_c('span',[_vm._v("Claim Rewards")])])],1):_vm._e(),(item.contractAddress && item.poolID)?_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":"","disabled":!_vm.connectedWallet ||
                      item.network != _vm.connectedWalletNetwork ||
                      item.wallet !== _vm.connectedWallet,"elevation":"2","outlined":"","x-small":""},on:{"click":function($event){return _vm.emergencyHarvest({
                        contractAddress: item.contractAddress,
                        poolIndex: item.poolID,
                        rawTokens: item.rawStakes,
                      })}}},'v-btn',attrs,false),on),[_vm._v(" E. WITHDRAW ")])]}}],null,true)},[_c('span',[_vm._v(" Withdraw without caring about rewards. EMERGENCY ONLY. ")])])],1):_vm._e()],1)]}},{key:"item.farmName",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-center align-center mr-2",staticStyle:{"width":"30px"}},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getNetworkLogo(item.network)}})],1)],1),_c('span',[_vm._v(_vm._s(item.farmName))])])]}},{key:"item.staked",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("to2Decimals")(item.actualStaked,_vm.round))+" ")]}},{key:"item.lpTotal",fn:function(ref){
                      var item = ref.item;
return _vm._l((item.lpBalances),function(balance,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm._f("to2Decimals")(balance,_vm.round))+" "+_vm._s(item.tokenSymbols[key])+" ")])})}},{key:"item.info",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start fill-height align-center"},[(_vm.historicalData.length > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"5px","height":"70%","margin-right":"10px"},style:({ backgroundColor: item.color })},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(item.wallet)+" ")])]):_vm._e(),(
                item.contractAddress &&
                _vm.farmInfoNetworks.includes(item.network)
              )?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.getPoolItemDetails({ item: item })}}},[_c('v-icon',[_vm._v(" mdi-information-outline ")])],1):_vm._e()],1)]}},{key:"item.tokenPair",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-center align-center mr-2",staticStyle:{"width":"30px"}},[(item.token1)?_c('v-avatar',{staticClass:"mr-n2",attrs:{"size":"20"}},[_c('v-img',{attrs:{"src":_vm.getTokenLogo(item.network, item.token1)}})],1):_vm._e(),_c('v-avatar',{attrs:{"size":"20"}},[_c('v-img',{attrs:{"src":_vm.getTokenLogo(item.network, item.token0)}})],1)],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.tokenPair))])])]}},{key:"item.pendingAmount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(item.pendingAmount))+" ")]}},{key:"item.poolValue",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(item.poolValue))+" ")]}},{key:"item.pending",fn:function(ref){
              var item = ref.item;
return [(item.pending > 0)?_c('div',[_vm._v(" "+_vm._s(_vm._f("to2Decimals")(item.pending,_vm.round))+" "),(item.rewardSymbol)?_c('span',[_vm._v(_vm._s(item.rewardSymbol))]):_vm._e()]):_vm._e(),(item.gambitRewards)?_c('div',_vm._l((item.gambitRewards),function(gReward,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm._f("to2Decimals")(gReward.pending,_vm.round))+" "+_vm._s(gReward.symbol)+" ")])}),0):_vm._e()]}}])}),_c('SingleFarmHistory'),_c('div',{staticClass:"card-padding d-flex justify-end"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
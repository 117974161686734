var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(Object.keys(_vm.farmsWithData).length)?_c('v-card-text',{staticClass:"px-0 py-0 overflow-hidden",staticStyle:{"border-radius":"0 0 24px 24px"}},[_c('v-expansion-panels',{attrs:{"accordion":"","hover":"","multiple":"","value":_vm.panelsArray,"tile":"","dark":_vm.darkmode}},_vm._l((_vm.farmsWithData),function(farm,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',{staticClass:"bg-transparent"},[_c('div',{staticClass:"d-flex justify-space-between mr-4 align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"mr-2 font-weight-bold d-flex align-center",staticStyle:{"height":"100%"}},[(_vm.historicalData.length > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"5px","margin-right":"5px"},style:({
                      backgroundColor: farm.color,
                      height: _vm.$vuetify.breakpoint.mobile ? '25px' : '40px',
                    })},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(farm.wallet)+" ")])]):_vm._e(),_c('v-avatar',{staticClass:"mr-2",attrs:{"rounded":"","tile":"","size":_vm.$vuetify.breakpoint.mobile ? '25' : '40'}},[_c('v-img',{attrs:{"src":_vm.getNetworkLogo(farm.network)}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{style:({
                    fontSize: _vm.$vuetify.breakpoint.mobile ? '10px' : '14px',
                  })},[_vm._v(" "+_vm._s(farm.name)+" ")]),_c('span',{style:({
                    fontSize: _vm.$vuetify.breakpoint.mobile ? '12px' : '14px',
                  })},[_c('span',{staticClass:"orange--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(farm.total))+" ")]),_vm._v(" ("+_vm._s(_vm._f("toSelectedCurrency")(farm.pendingTotal))+") ")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"text-none",attrs:{"dense":"","outlined":"","color":"indigo lighten-1","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openHistoricalChartModal(farm)}}},[_c('v-icon',[_vm._v(" mdi-chart-timeline-variant ")]),_c('span',{staticClass:"ml-2 d-none d-sm-block"},[_vm._v(" View History ")])],1)],1)])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"pa-0"},[_c('Farm',{attrs:{"farm":farm}})],1)])],1)}),1),_c('SingleFarmHistoryChart',{attrs:{"farm":_vm.selectedFarm}}),_c('SingleFarmHistory')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6",style:({
    filter: _vm.darkmode
      ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
      : '',
  }),attrs:{"dark":_vm.darkmode}},[_c('v-card-text',{staticClass:"card-stats-padding"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-bitcoin ")]),_c('h4',{staticClass:"font-weight-600 text-muted text-uppercase text-h5 mb-0"},[_vm._v(" Token Distribution ")])],1),_c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"width":"500","overlay-color":_vm.darkmode ? 'grey' : 'rgb(33, 33, 33)',"overlay-opacity":_vm.darkmode ? 0.2 : 0.46},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-caption text-none font-weight-bold white--text",attrs:{"elevation":"0","small":"","dark":_vm.darkmode,"color":"#5e72e4","disabled":_vm.tokens.length === 0}},'v-btn',attrs,false),on),[_vm._v(" View All ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"black-shadow",staticStyle:{"max-height":"600px"},attrs:{"dark":_vm.darkmode}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold flex justify-center lighten-2"},[_vm._v(" Token Distribution ")]),_c('v-divider'),_c('v-card-text',{staticClass:"custom-scrollbar pa-0",staticStyle:{"max-height":"500px","overflow-y":"auto","overflow-x":"hidden"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.tokens,"item-key":"name","hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"font-weight-bold flex"},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getTokenLogo(item.network, item.token)}})],1),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('td',[_vm._v(_vm._s(item.amount.toFixed(2)))]),_c('td',[_vm._v(_vm._s(_vm._f("toSelectedCurrency")(item.value)))]),_c('td',[_c('span',{staticClass:"text-right",staticStyle:{"mcolorsin-width":"50px"}},[_vm._v(" "+_vm._s(_vm.getPercentage(item.value).toFixed(2))+"% ")])])])]}}])})],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","indeterminate":_vm.loading,"color":"indigo lighten-1"},slot:"progress"}),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"})]),(_vm.tokens.length > 0)?_c('div',{staticClass:"d-flex"},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"pa-10 d-flex justify-center",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('DoughnutChart',{ref:"portfolioExposureChart",staticStyle:{"max-height":"150px","max-width":"150px"},attrs:{"chart-data":_vm.chartData,"options":_vm.options,"chart-labels":_vm.chartLabels,"colors":_vm.colors}})],1)]),_c('v-col',{staticClass:"pa-10 d-flex flex-column justify-center",attrs:{"cols":"12","md":"8"}},[_vm._l((_vm.mappedTokens),function(token,index){return _c('v-flex',{key:index,staticClass:"mb-3 mx-auto",staticStyle:{"max-width":"250px","width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"rounded-circle mr-2",style:({
                    backgroundColor: ("" + (_vm.labelColors[index])),
                    width: '13px',
                    height: '13px',
                  })}),_c('div',{staticClass:"d-flex justify-center align-center mr-1",staticStyle:{"width":"30px"}},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getTokenLogo(token.network, token.token)}})],1)],1),_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(token.name)+" ")])]),_c('v-divider',{staticClass:"mx-2"}),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm.getPercentage(token.value).toFixed(2))+"% ")])])],1)])}),(_vm.otherTokens)?_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"250px","width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"rounded-circle mr-2",style:({
                    background: ("" + (_vm.labelColors[5])),
                    width: '13px',
                    height: '13px',
                  })}),_c('div',{staticClass:"d-flex justify-center align-center mr-1",staticStyle:{"width":"30px"}},[_c('v-avatar',{attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-icon',{attrs:{"color":"#5e72e4","size":"20"}},[_vm._v(" mdi-shield-link-variant ")])],1)],1),_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.otherTokens.name)+" ")])]),_c('v-divider',{staticClass:"mx-2"}),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm.getPercentage(_vm.otherTokens.value).toFixed(2))+"% ")])])],1)]):_vm._e()],2)],1)],1):_c('div',{staticClass:"d-flex align-center justify-content-center flex-column py-10 w-full"},[_vm._v(" No data available... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":_vm.loading,"color":"indigo lighten-1"}}),(_vm.balances.length)?_c('v-card',{staticClass:"rounded-t-0",staticStyle:{"overflow":"hidden"}},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"})]),_c('v-data-table',{staticClass:"table px-4 custom-scrollbar",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.balances,"hide-default-footer":"","page":_vm.page,"mobile-breakpoint":"0","sort-by":"tokenValue","calculate-widths":"","sort-desc":true},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center fill-height"},[(_vm.historicalData.length > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"5px","height":"70%","margin-right":"10px"},style:({ backgroundColor: item.color })},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(item.wallet)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex font-weight-bold"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"rounded":"","tile":"","size":"20"}},[_c('v-img',{attrs:{"src":_vm.getNetworkLogo(item.network)}})],1),_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"20"}},[_c('v-img',{attrs:{"src":_vm.getTokenLogo(item.network, item.tokenAddress)}})],1)],1),_vm._v(" "+_vm._s(item.symbol)+" ")])],1)]}},{key:"item.tokenBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("to2Decimals")(item.tokenBalance,_vm.round))+" ")]}},{key:"item.tokenPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(item.tokenPrice))+" ")]}},{key:"item.tokenValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSelectedCurrency")(item.tokenValue))+" ")]}}],null,false,2009254285)})],1),_c('div',{staticClass:"card-padding d-flex justify-end"},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"indigo lighten-1","length":_vm.pageCount,"circle":"","total-visible":_vm.$vuetify.breakpoint.smAndDown ? 4 : 8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_c('NoDataCard')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
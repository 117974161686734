var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-3",staticStyle:{"background-color":"transparent"},attrs:{"dark":_vm.darkmode}},[_c('v-card-text',{staticClass:"card-stats-padding"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row justify-space-between align-center"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4 mb-lg-0",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center mb-lg-0"},[_c('v-icon',[_vm._v(" mdi-chart-timeline-variant-shimmer ")]),_c('h4',{staticClass:"font-weight-600 text-uppercase text-caption text-uppercase mb-0 mx-2",style:({ color: _vm.darkmode ? '#d5d5d5' : '#15121D' })},[_vm._v(" Historical Profile ")]),_c('v-tooltip',{attrs:{"top":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"d-flex align-center text-caption font-weight-bold grey--text text--darken-4",staticStyle:{"font-size":"10px !important"},attrs:{"small":"","color":"yellow"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v(" mdi-alert-octagon ")]),_vm._v(" Beta "+_vm._s(_vm.$vuetify.breakpoint.xlAndUp ? "Feature" : "")+" ")],1)]}}]),model:{value:(_vm.isTooltipOpen),callback:function ($$v) {_vm.isTooltipOpen=$$v},expression:"isTooltipOpen"}},[_c('span',{staticClass:"text-caption text-white font-weight-bold",on:{"click:outside":function($event){_vm.isTooltipOpen = false}}},[_vm._v(" This feature is currently in beta version. If you see some bugs, let us know "),_c('a',{staticClass:"font-weight-bold text-decoration-none blue--text text--lighten-2",attrs:{"href":"https://0xtracker.hellonext.co/roadmap"}},[_vm._v(" here ")]),_vm._v(". ")])])],1),_c('div',{staticClass:"d-flex"},[(
              _vm.$vuetify.breakpoint.mdAndDown &&
              _vm.historicalData.length > 0 &&
              _vm.showHistoricalData
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"rounded-lg mr-2",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false,"disabled":!_vm.connectedWallet},on:{"click":function($event){return _vm.$router.push('cleaner')}}},[_c('v-icon',{staticClass:"show-button",attrs:{"size":"15"}},[_vm._v(" mdi-broom ")])],1)],1)]}}],null,false,1405460371)},[(_vm.connectedWallet)?_c('span',[_vm._v("Select Data To Remove (Clean)")]):_c('span',[_vm._v("Connect wallet to clean up records")])]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false},on:{"click":function($event){_vm.showHistoricalData = !_vm.showHistoricalData}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"show-button",attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showHistoricalData ? "mdi-minus-thick" : "mdi-plus-thick")+" ")])],1)]}}],null,false,2191201085)},[(_vm.showHistoricalData)?_c('span',[_vm._v("Hide Historical Data")]):_c('span',[_vm._v("Show Historical Data")])]):_vm._e()],1)]),(_vm.historicalData.length > 0 && _vm.showHistoricalData)?_c('v-btn-toggle',{staticClass:"ml-0 ml-lg-2",style:({
          filter: _vm.darkmode
            ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
            : '',
        }),attrs:{"mandatory":"","dense":"","dark":_vm.darkmode},model:{value:(_vm.selectedInterval),callback:function ($$v) {_vm.selectedInterval=$$v},expression:"selectedInterval"}},_vm._l((_vm.intervals),function(interval,index){return _c('v-btn',{key:index,attrs:{"active-class":"active-btn","icon":_vm.$vuetify.breakpoint.lgAndDown}},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(interval.text)+" ")])])}),1):_vm._e(),(
          _vm.$vuetify.breakpoint.lgAndUp &&
          _vm.historicalData.length > 0 &&
          _vm.showHistoricalData
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"rounded-lg ml-2",staticStyle:{"text-decoration":"none"},attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false,"disabled":!_vm.connectedWallet},on:{"click":function($event){return _vm.$router.push('cleaner')}}},[_c('v-icon',{staticClass:"show-button",attrs:{"size":"15"}},[_vm._v(" mdi-broom ")])],1)],1)]}}],null,false,1335411248)},[(_vm.connectedWallet)?_c('span',[_vm._v("Select Data To Remove (Clean)")]):_c('span',[_vm._v("Connect wallet to clean up records")])]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"rounded-lg ml-2",attrs:{"depressed":"","outlined":"","icon":"","small":"","ripple":false},on:{"click":function($event){_vm.showHistoricalData = !_vm.showHistoricalData}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"show-button",attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showHistoricalData ? "mdi-minus-thick" : "mdi-plus-thick")+" ")])],1)]}}],null,false,1422713059)},[(_vm.showHistoricalData)?_c('span',[_vm._v("Hide Historical Data")]):_c('span',[_vm._v("Show Historical Data")])]):_vm._e()],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","indeterminate":_vm.loading,"color":"indigo lighten-1"},slot:"progress"}),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"})]),(_vm.showHistoricalData)?_c('div',[(_vm.historicalData.length > 0)?_c('div',{staticClass:"d-flex"},[_c('v-row',{staticClass:"d-flex align-center mt-2",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('LineChart',{ref:"historicalDataRef",staticStyle:{"width":"100%","overflow":"visible"},style:({
                height: _vm.$vuetify.breakpoint.mdAndUp ? '100%' : '300px',
              }),attrs:{"datasets":_vm.datasets,"options":_vm.options}})],1),_c('v-col',{staticClass:"d-flex justify-center flex-column align-end",attrs:{"cols":"12"}},_vm._l((_vm.historicalData),function(data,index){return _c('div',{key:index,staticClass:"d-flex align-center mb-1",staticStyle:{"max-width":"350px","width":"100%"}},[_c('div',{staticClass:"rounded-circle mr-3",staticStyle:{"min-width":"15px"},style:({
                  width: '15px',
                  height: '15px',
                  backgroundColor: _vm.colors[index],
                })}),_c('span',{staticClass:"text-caption font-weight-bold",staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(data.wallet.walletAddress)+" ")])])}),0)],1)],1):_c('div',{staticClass:"d-flex align-center justify-content-center flex-column py-10 w-full"},[_vm._v(" No data available... ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }